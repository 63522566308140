<template>
    <div>
        <el-card style="margin-bottom: 10px">
            <nav class="out">
                <nav class="input">
                    <el-input v-model="querifo.keyword" size="medium" placeholder="输入昵称，姓名，手机，公司" />
                </nav>

                <nav class="input" style="width: 150px">
                    <el-select v-model="querifo.certStatus" placeholder="状态" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option label="待审核" :value="1" />
                        <el-option label="审核不通过" :value="3" />
                    </el-select>
                </nav>
                <label for="" style="font-size: 14px; float: left; transform: translateY(9px)">提交日期：</label>
                <nav class="input" style="width:300px">
                    <!-- <div class="box" style="width:300px"> -->
                    <el-date-picker
                        v-model="querifo.certDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: 100%"
                        size="medium"
                    />
                    <!-- </div> -->
                </nav>
                <nav class="input">
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="primary" size="small" @click="subimt">{{ btn ? "搜索" : "loading.." }}</el-button>
                </nav>
            </nav>
        </el-card>
        <el-card class="box-card">
            <el-table :data="userlist" border stripe :header-cell-style="rowClass" @sort-change="sortChange">
                <el-table-column label="ID" prop="id" />
                <el-table-column label="头像" prop="avatar" width="110px">
                    <template slot-scope="{ row }">
                        <img
                            :src="!!row.avatar ? baseurl + yu + row.avatar : baseurl + yu + '/static/pc/img/avatar.png'"
                            alt=""
                            style="width: 80px; height: 80px"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="认证凭证" prop="businessCard" width="110px">
                    <template slot-scope="{ row }">
                        <el-image
                            style="width: 80px;"
                            :src="baseurl + yu + row.businessCard"
                            :preview-src-list="[baseurl + yu + row.businessCard]"
                        >
                            <div slot="error">
                                <i class="el-icon-picture-outline" />
                            </div>
                        </el-image>
                    </template>
                </el-table-column>

                <el-table-column label="昵称" prop="nickname" />
              <el-table-column label="姓名" prop="realName" >
                <template slot-scope="{ row }">
                  <div @click="toUser(row)" style="cursor: pointer;">
                    {{ row.realName }}
                  </div>
                </template>
              </el-table-column>
                <el-table-column label="职位" prop="job" />

                <el-table-column label="职级" prop="jobLevelName" />

                <el-table-column label="公司" prop="company" />
                <!-- <el-table-column label="用户类型" prop="phone">
          <template slot-scope="{ row }">
            {{ row.typeName.toString() }}/
            {{ row.businessName.toString() }}
          </template>
        </el-table-column> -->
                <el-table-column label="业务介绍" prop="businessInfo" :show-overflow-tooltip="true" width="300" />
                <el-table-column label="状态">
                    <template slot-scope="ew">
                        <!-- {{ scope.row }} -->
                        <el-tag v-show="ew.row.certStatus == 3" type="danger">审核不通过</el-tag>
                        <el-tag v-show="ew.row.certStatus == 1" type="warning">认证待审核</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="提交时间" prop="certCreateTime" />
                <el-table-column label="备注" prop="remark">
                    <!-- <template slot-scope="{ row }">
            <Remark
              :row="row"
              :val="{
                url: '/admin/User/cert',
                pw: 'user_enterprise_remark'
              }"
              @getlist="getuserlist"
            ></Remark>
          </template> -->
                </el-table-column>
                <el-table-column label="操作" width="180px">
                    <template slot-scope="scope">
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_cert_check_edit') != -1"
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            class="skyblue"
                            @click="edit_user(scope.row.id)"
                            >编辑
                        </el-button>
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_cert_check') != -1"
                            :loading="loadObject.audit"
                            type="text"
                            icon="el-icon-setting"
                            size="mini"
                            style="color:tan;"
                            @click="setRole(scope.row, 2)"
                            >审核通过
                        </el-button>
                        <div>
                            <el-button
                                v-show="$store.state.btn_if.indexOf('user_cert_check') != -1"
                                type="text"
                                icon="el-icon-setting"
                                size="mini"
                                class="pink"
                                @click="showShk(scope.row)"
                                >审核不通过</el-button
                            >
                        </div>
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_get_contact') != -1"
                            type="text"
                            icon="el-icon-camera"
                            size="mini"
                            class="green"
                            @click="seeTheLink(scope.row.id)"
                            >查看联系方式</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="querifo.page"
                :page-size="querifo.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="50%" @close="addColse">
            <!-- 内容主题区域 -->
            <el-form ref="addFormRef" label-width="80px" :model="addform" :rules="addFormRulue">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="addform.username" />
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="addform.password" />
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="addform.email" />
                </el-form-item>
                <el-form-item label="手机" prop="mobile">
                    <el-input v-model="addform.mobile" />
                </el-form-item>
            </el-form>

            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUser">确 定</el-button>
            </span>
        </el-dialog>
        <dilog :dialog-visible.sync="shk" :form="shkform" @getlist="getuserlist" />
        <!-- <img-zoom ref="imgzoom"></img-zoom> -->
        <member ref="member" @getlist="getuserlist" />
        <Slink ref="link" />
    </div>
</template>

<script>
// import { getChannelList } from '../api/axiso.js'
import Remark from "../../components/remark/index";
import Dilog from "../../components/dilog.vue";
export default {
    components: {
        Remark,
        Dilog,
        member: () => import("@/components/member.vue"),
        Slink: () => import("@/components/seeTheLink.vue"),
    },
    data() {
        var checkEmail = (rule, value, cb) => {
            // 这是验证邮箱的正则表达式
            const regemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (regemail.test(value)) {
                return cb();
            }
            cb(new Error("请输入合格邮箱"));
        };
        var checkMoblle = (rule, value, cb) => {
            const regphone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
            if (regphone.test(value)) {
                return cb();
            }
            cb(new Error("请输入合法的手机号"));
        };

        return {
            province: "",
            btn: true,
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            shk: false,
            shkform: {},
            regDate: [],
            querifo: {
                keyword: "",
                page: 1,
                pageSize: 10,
                certStatus: "",
                certDate: [],
            },
            order: "",
            userlist: [],
            total: 0,
            addDialogVisible: false,
            loadObject: {
                audit: false,
            },
            addform: {
                username: "",
                password: "",
                email: "",
                mobile: "",
            },
            addFormRulue: {
                nickname: [
                    { required: true, message: "请输入昵称", trigger: "blur" },
                    // {
                    //   min: 3,
                    //   max: 10,
                    //   message: '用户的长度在3~10个字符直接',
                    //   trigger: 'blur'
                    // }
                ],
                realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
                businessInfo: [{ required: true, message: "请输入业务介绍", trigger: "blur" }],
                company: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
                job: [{ required: true, message: "请输入职位", trigger: "blur" }],
                province: [{ required: true, message: "请输入城市", trigger: "blur" }],
                contactType: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
                type: [{ required: true, message: "请输入类型", trigger: "blur" }],
                business: [{ required: true, message: "请输入行业", trigger: "blur" }],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    {
                        min: 6,
                        max: 15,
                        message: "用户的长度在6~15个字符直接",
                        trigger: "blur",
                    },
                ],
                email: [
                    { required: true, message: "请输入邮箱", trigger: "blur" },
                    { validator: checkEmail, trigger: "blur" },
                ],
                mobile: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { validator: checkMoblle, trigger: "blur" },
                ],
                realname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
            },
            editdialog: false,
            editform: {
                realName: "",
                nickname: "",
                email: "",
                phone: "",
                remark: "",
                waId: "",
                weixin: "",
                businessInfo: "",
            },
            setRoledingvisable: false,
            userinfo: "",
            // 所有角色数据列表
            roleslist: [],
            slectroleName: "",
            Config: this.$store.state.config,
            status: "",
            service: "",
            paiform: {},
            header: {
                Token: sessionStorage.getItem("token"),
            },
            action: {
                action: "user",
            },
            city: "",
        };
    },

    computed: {
        citys() {
            return async function(val) {
                console.log(val);

                const { data: res } = await this.$http.get("/admin/Area/getList?code=" + val);
                console.log(res);
                return { code: 5, sds: 3 };
                // .then(({ data: res }) => {
                //   if (res.errorCode == 200) {
                //     console.log(1);

                //   }
                // })
            };
        },
    },
    created() {
        this.getuserlist();
    },
    mounted() {},
    methods: {
        seeTheLink(val) {
            this.$refs.link.getTheDetails(val);
        },
        async edit_user(id) {
            const { data: res } = await this.$http.get("/admin/User/getById?id=" + id);
            if (res.errorCode != 200) {
                return this.$message.error("查询用户失败");
            }
            this.$refs.member.editform = {
                ...res.data,
                waId: res.data.waId && res.data.waId.toString().split(","),
                showContacts: (res.data.showContacts && res.data.showContacts.toString().split(",")) || [],
                jobLevel: res.data.jobLevel || "",
                enterpriseId: res.data.enterpriseId || "",
            };
            if (res.data.enterpriseId) {
                this.$refs.member.options = [{ value: res.data.enterpriseId, label: res.data.company }];
            }

            this.$refs.member.addDialogVisible = true;

            // console.log(this.editform.business);

            this.editform.business = (this.editform.business && this.editform.business.split(",")) || "";
            this.editform.type = (this.editform.type && this.editform.type.split(",")) || "";
        },

        op() {
            this.getc();
            this.editform.city = "";
        },
        showShk(row) {
            this.shk = true;
            this.shkform = {
                ...row,
            };
        },
        async handleInputBlur(row) {
            this.paiform.id = row.id;
            this.paiform.remark = row.remark;
            this.paiform.waId = row.waId.join(",");
            console.log(this.paiform);
            const { data: res } = await this.$http.post("/admin/User/editInfo", this.paiform);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                //  row.pai=false
                //  this.tableData=[...this.tableData]
                this.getuserlist();
            }
        },
        rowClass() {
            return "background:#F5F7FA;";
        },
        getc() {
            this.$http.get("/admin/Area/getList?code=" + this.editform.province).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    console.log(this.editform.province);
                    console.log(res);
                    this.city = res.data;
                }
            });
        },
      /**
       * 姓名点击跳转至会员
       * @param val
       */
      toUser(val) {
        // console.log(val);
        let { id } = val;
        this.$router.push({
          path: "/user_list",
          query: {
            userId:id,
          },
        });
      },
        sortChange(c) {
            console.log(c);
            if (c.order) {
                var s = "user_" + c.prop;
                if (c.prop == "orderNum") {
                    s = "activity_order_num";
                } else {
                    s = "user_" + c.prop;
                }
                var p = c.order.slice(0, c.order.length - 6);
                this.order = s + " " + p;
            } else {
                this.order = "";
            }

            this.getuserlist();
        },
        subimt() {
            this.querifo.page = 1;
            this.getuserlist();
        },
        async getuserlist() {
            const { data: res } = await this.$http.get("/admin/User/certList", {
                params: {
                    ...this.querifo,
                    certDate: this.querifo.certDate.join("~"),
                },
            });
            if (res.errorCode != 200) {
                return;
            }
            this.userlist = res.data.list;
            this.userlist.forEach((item) => {
                if (item.waId) {
                    item.waId = item.waId.split(",");
                }
            });
            this.total = res.data.totalCount;
            // console.log(res)
        },
        handleSizeChange(e) {
            // console.log(e)
            this.querifo.pageSize = e;
            this.getuserlist();
        },
        handleCurrentChange(e) {
            // console.log(e)
            this.querifo.page = e;
            this.getuserlist();
        },
        async userstatechange(userinfo) {
            // 监听switch开关状态改变
            // console.log(userinfo)
            const { data: res } = await this.$http.put(`users/${userinfo.id}/state/${userinfo.mg_state}`);
            // console.log(res)
            if (res.meta.status != 200) {
                userinfo.mg_state = !userinfo.mg_state;
                return this.$message.error("更新用户失败");
            }
            this.$message.success("更新用户成功");
        },
        addColse() {
            this.$refs.addFormRef.resetFields();
        },
        editclose() {
            this.$refs.editFormRef.resetFields();
        },
        addUser() {
            this.$refs.addFormRef.validate(async (val) => {
                // console.log(val)
                if (!val) return;
                const { data: res } = await this.$http.post("users", this.addform);
                if (res.meta.status != 201) {
                    this.$message.error("添加用户失败");
                }
                this.$message.success("添加用户成功");
                this.addDialogVisible = false;
                this.getuserlist();
            });
        },
        reset() {
            this.querifo = this.$options.data.call(this).querifo;
            this.getuserlist();
        },
        // async showEdit(id) {
        //     // console.log(id)
        //     this.editform.id = id;
        //     const { data: res } = await this.$http.get("/admin/User/getById?id=" + id);
        //     if (res.errorCode != 200) {
        //         return this.$message.error("查询用户失败");
        //     }
        //     this.editform = {
        //         ...res.data,
        //         waId: !!res.data.waId && res.data.waId.split(","),
        //         showContacts: (!!res.data.showContacts && res.data.showContacts.split(",")) || [],
        //     };
        //     // console.log(this.editform.business);

        //     this.editform.business = this.editform.business.split(",");
        //     console.log(this.editform.business);

        //     if (this.editform.province) {
        //         this.getc();
        //     }
        //     this.editdialog = true;
        // },
        clear() {
            this.editform.business = [];
            console.log(this.editform);
        },
        edituserinfo() {
            const form = {
                ...this.editform,
                business: this.editform.business.toString(),
                waId: this.editform.waId.toString(),
                showContacts: this.editform.showContacts.toString(),
            };
            // console.log(1)
            if (this.btn) {
                this.$refs.editFormRef.validate(async (val) => {
                    if (!val) return;
                    const { data: res } = await this.$http.post("/admin/User/edit", form);
                    if (res.errorCode == 200) {
                        this.$message.success("更新成功");
                        this.editdialog = false;
                        this.getuserlist();
                    }
                });
            }
            // this.btn = false
        },

        async removeuserid(id) {
            // console.log(id)
            await this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http
                        .delete("/admin/User/del", { params: { id: id } })
                        .then(({ data: res }) => {
                            if (res.errorCode == 200) {
                                this.$message.success(res.message);
                                if (this.total % 10 == 1) {
                                    this.querifo.page--;
                                }
                                this.getuserlist();
                            }
                        })
                        .catch(() => {
                            return this.$message.error("删除用户失败");
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 这是展示分配角色的对话框
        async setRole(row, info) {
            if (!row.enterpriseId) {
                await this.$confirm(
                    `当前用户未关联企业，请通过“编辑”操作关联企业后再审核！<br><span style="color:red">如果该企业未录入企业库，强制审核通过将不利于企服营销推广的数据推荐</span>`,
                    "提示",
                    {
                        confirmButtonText: "审核通过",
                        cancelButtonText: "取消",
                        dangerouslyUseHTMLString: true,
                        type: "warning",
                    }
                );
                this.beApproved(row, info);
                return;
            }
            this.beApproved(row, info);
        },
        async beApproved(row, info) {
            this.loadObject.audit = true;
            let { data: res } = await this.$http.post("/admin/User/cert", {
                ...row,
                certStatus: info,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getuserlist();
            }
            setTimeout(() => {
                this.loadObject.audit = false;
            }, 800);
        },
        saveinRole() {
            if (!this.slectroleName) {
                return this.$message.error("请选择你要分配的角色");
            }
            this.$http
                .put(`users/${this.userinfo.id}/role`, {
                    rid: this.slectroleName,
                })
                .then(() => {
                    this.$message.success("更新角色成功");
                    this.getuserlist();
                    this.setRoledingvisable = false;
                })
                .catch(() => {
                    this.$message.error("更新角色失败!");
                });
        },
        setRoleclosed() {
            this.slectroleName = "";
        },
        handleAvatarSuccess(res) {
            this.editform.avatar = res.data.url;
        },
    },
};
</script>

<style lang="less" scoped>
.box {
    display: inline-block;
    margin-left: 15px;
}
.skyblue {
    color: skyblue;
}
.green {
    color: green;
}
.yellow {
    color: pink;
}
.red {
    color: red;
}
</style>
